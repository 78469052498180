import React from 'react'
import './Hero.css'
import { HeroData } from '../../utils/data.js'

const Hero = () => {

  return (
    <div className="h-wrapper">
        <div className="container">
            {/* <img src="hero/hero-arrow.webp" alt="hero-arrow" className="h-arrow" /> */}
            <div className="h-container">
                {/* left Side */}
                <div className="h-left">
                    <div className="image-row">
                        {HeroData.slice(0, 3).map((person, i) => (
                        <div className='person-pill' key={i}>
                            <div className="person-pill-bg">
                            <img src={person.src} alt={`Hero ${i}`} />
                            </div>
                        </div>
                        ))}
                    </div>

                    <div className="image-row">
                        {HeroData.slice(3,6).map((person, i) => (
                            <div className='person-pill' key={i}>
                                <div className="person-pill-bg">
                                <img src={person.src} alt={`Hero ${i}`} />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                {/* right Side */}
                <div className="h-right">
                    <div className="h-title">
                        <span>Best Accounting</span>
                        <span>Services</span>
                        <span>in UAE</span>
                    </div>

                    <div className="h-description">
                        Maxims Auditors & Consultants
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Hero