import React from 'react'
import './WhatWeDo.css'
import { motion } from 'framer-motion'
import { containerVariants, desVariants, tagVariants, titleVariants } from '../../utils/animation.js'

const WhatWeDo = () => {
  return (
    <div className="wwd-wrapper">
        <div className="container">
            <div className="wwd-container">
                {/* head of section */}
                <div className="wwd-head">
                    <motion.span
                    initial="offscreen"
                    whileInView={"onscreen"}
                    variants={tagVariants}
                    className='tag'
                    >What we offer</motion.span>
                    <motion.span
                    initial="offscreen"
                    whileInView={"onscreen"}
                    variants={titleVariants}
                    className='titles'
                    >
                        {" "}
                        To achieve financial success, we think it is essential to choose the best financial services company
                    </motion.span>
                    <motion.span
                    initial="offscreen"
                    whileInView={"onscreen"}
                    variants={desVariants}
                    className='des'
                    >A wide range of services are available from highly skilled tax consultants with years of experience in the industry to assist you in creating a secure financial future. When you receive tax counsel in a timely manner, you might prevent several issues with the tax authorities. We will assist you as quickly and effectively as we can.</motion.span>
                </div>

                {/* two blocks */}
                <div className="wwd-blocks">
                    {/* first block */}
                    <div className="wwd-block">
                        <motion.span 
                        variants={titleVariants}
                        initial="offscreen"
                        whileInView={"onscreen"}
                        className='sec-title'>
                            Our Vision
                        </motion.span>
                        <motion.span 
                        variants={desVariants}
                        initial="offscreen"
                        whileInView={"onscreen"}
                        className='text'>
                           We wants to provide UAE-based companies more financial control and self-reliance so they can succeed. We work to take use of their expertise to offer exact and thorough management accounting, cost analysis, and financial consulting services that help businesses and organisations succeed. By reforming businesses and advancing the creation of a more financially responsible society, our team of highly qualified experts aspires to have a big impact on the UAE.
                        </motion.span>
                        
                    </div>
                    {/* second block */}
                    <div className="wwd-block">
                        <motion.span 
                        variants={titleVariants}
                        initial="offscreen"
                        whileInView={"onscreen"}
                        className='sec-title'>
                           Our Mission
                        </motion.span>
                        <motion.span 
                        variants={desVariants}
                        initial="offscreen"
                        whileInView={"onscreen"}
                        className='text'>
                            Maxims Auditors and Consultants is striving to be the top provider of extensive financial services in the UAE by prioritizing the use of ethically derived financial tactics to deliver the best possible outcomes for their clients. Our aim to establish ourselves as a fertile platform for financial professionals with proven skills, providing highly effective, comprehensive, and reliable services that help clients achieve their objectives and succeed in the competitive UAE.
                        </motion.span>
                       
                    </div>
                </div>

                {/* support block */}
                <motion.div 
                initial="offscreen"
                whileInView={"onscreen"}
                variants={containerVariants(0.3)}
                className="wwd-support">
                    {/* left side */}
                    <div>
                        <span className="sec-title">
                        Accounting Services
                        </span>
                        <span className="des">
                        Maxims Auditors and Consultants treat each client as unique and ensure that we provide them with truly professional services. We are committed to maintaining the quality of our services.
                        </span>
                    </div>

                    {/* right side */}
                    <div>
                        <span className='text pt-3 pt-md-0'>
                        Elevate your company's financial efficiency with top-notch accounting services in UAE. Our comprehensive offerings encompass expert inventory management, precise project accounting, meticulous financial reporting, and more. 
                        </span>
                        <span className='text'>
                        By leveraging our specialized accounting solutions tailored for the UAE market, you can streamline operations, gain valuable insights, and ensure regulatory compliance. Whether it's optimizing inventory levels, tracking project expenses, or generating insightful financial reports, our services empower your business to thrive in the dynamic landscape of the UAE. Trust us to be your strategic partner in driving financial excellence and achieving your business objectives.
                        </span>
                    </div>
                </motion.div>
            </div>
        </div>
    </div>
  )
}

export default WhatWeDo