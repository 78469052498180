// "use client"

import React, { useState } from 'react'
import './Navbar.css'
import {BiMenuAltRight} from 'react-icons/bi'
import {RxCross2} from 'react-icons/rx'
import {useMotionValueEvent, useScroll} from 'framer-motion';
import { Link } from 'react-scroll'
import { NavLink } from 'react-router-dom';
// import maximsLogo from '../../assets/logo3.webp';
import maximsLogo from '../../assets/updatedlogo.webp';
// import maximsLogoAlt from '../../assets/logos.webp';


const Navbar = () => {

    const [mobileMenuOpened, setMobileMenuOpened] = useState(false);
    const [navStyle, setNavStyle] = useState("");
    const [logo, setLogo] = useState(maximsLogo); // Default logo

    const { scrollYProgress } = useScroll();
    const isSticky = navStyle.includes("sticky");

    useMotionValueEvent(scrollYProgress, "change", (latest) => {
        if (latest > 0.1) {
            setNavStyle("sticky");
            // Change logo based on scroll or any other condition
            setLogo(maximsLogo); // Set the alternate logo
        } else {
            setNavStyle("");
            setLogo(maximsLogo); // Set back to default logo
        }
    });

  return (
    <div className={`n-wrapper ${navStyle}`}>
        {/* desktop version */}
        <div className="container">
            <div className="n-container">
                {/* left side */}
                <div className="n-logo" style={{width:'50%'}}>
                <img src={logo} alt="Maxims Logo" style={{width:'50%', paddingLeft: '10px'}} className="logo-image" />
                    {/* <span>Maxims Auditors & Consultants</span> */}
                </div>

                {/* right side */}
                <div className="n-right">
                    <div className="n-menu">
                        <Link to="wwd-wrapper" spy={true} smooth={true} duration={300}>
                            <span>Home</span>
                        </Link>
                        <Link to="hiw-wrapper" spy smooth offset={100} duration={300}>
                            <span>Our Services</span>
                        </Link>
                        <Link to="wwi-wrapper" spy smooth offset={100} duration={300}>
                            <span>About</span>
                        </Link>
                        <NavLink to="/corporatetax" className={`corparate-color ${isSticky ? 'active-color' : ''}`} style={{ textDecoration: 'none' }}>
                                <span>Corporate Tax</span>
                            </NavLink>
                            <NavLink to="/blog" className={`corparate-color ${isSticky ? 'active-color' : ''}`} style={{ textDecoration: 'none' }}>
                                <span>Blog</span>
                            </NavLink>
                    </div>
                    <NavLink to="/contact" style={{ textDecoration: 'none' }}>
                        <div className="fund-button">
                            Contact
                        </div>
                    </NavLink>
                </div>
            </div>
        </div>

        {/* mobile version */}
        <div className="nm-container">
            {/* logo */}
            <img src={logo} alt="Maxims Logo" style={{width:'160px'}} className="logo-image" />
            {/* <span>Maxims Auditors & Consultants</span> */}

            {/* menu icon */}
            {
                !mobileMenuOpened ? 
                <BiMenuAltRight
                size={30}
                onClick={() => setMobileMenuOpened(true)} 
                /> : 
                <RxCross2 
                size={30}
                onClick={() => setMobileMenuOpened(false)}
                />
            }

            {/* mobile menu */}
            <div className="nm-menu"
            style={{transform: mobileMenuOpened && "translateX(0%)", marginTop:'1%'}}
            >
                <span>Home</span>
                {/* <span>Our Services</span>
                <span>About</span> */}
                <span><NavLink to="/corporatetax" style={{color:'white', textDecoration: 'none' }}>Corporate Tax</NavLink></span>
                
                <span><NavLink to="/blog" style={{color:'white', textDecoration: 'none' }}>Blog</NavLink></span>
                <NavLink to="/contact" style={{ textDecoration: 'none' }}>
                    <div className="m-funded-button">
                    Contact
                    </div>
                </NavLink>
            </div>

        </div>
    </div>
  )
}

export default Navbar